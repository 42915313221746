<template>
    <div class="card-stack">
        <portal to="edit-actions">
            <btn-undo
                class="btn-min-width mr-3"
                :resource="group"
            />

            <btn-resource
                class="btn-min-width"
                :resource="group"
                type="submit"
                variant="primary"
                @click="group.save()"
            >
                {{ $t('common.actions.saveChanges') }}
            </btn-resource>
        </portal>

        <b-card>
            <b-card-title title-tag="h2">
                {{ $t('groups.form.titleFields') }}
            </b-card-title>

            <group-form :group="group" />
        </b-card>

        <dangerous-action-card
            :confirmation-message="$t('common.messages.deleteConfirmation', {object: group.name})"
            :resource="group"
            :title="$t('groups.form.actions.remove.title')"
            :message="$t('groups.form.actions.remove.message')"
            :action-label="$t('common.actions.remove')"
            @confirmed="deleteOrganisation"
        />
    </div>
</template>

<script>
import DangerousActionCard from '@/components/common/DangerousActionCard';
import {detainFeedback} from '@/library/helpers';
import {Group} from '@/models/vue-mc/Group';
import GroupForm from '@/components/groups/Form';

export default {
    name: 'GroupsEdit',
    components: {DangerousActionCard, GroupForm},
    props: {
        group: {
            type: Group,
            required: true,
        },
    },
    methods: {
        async deleteOrganisation() {
            await this.value.delete();

            detainFeedback(() => {
                this.$router.go(-1);
            });
        },
    },
};
</script>

