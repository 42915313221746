<template>
    <b-card
        bg-variant="transparent"
        body-class="bg-striped d-flex align-items-center py-5"
    >
        <div class="mr-3">
            <h6
                v-if="title"
                class="mb-1 font-weight-bolder"
            >
                {{ title }}
            </h6>

            <small
                v-if="message"
                class="text-muted"
            >
                {{ message }}
            </small>
        </div>

        <btn-resource
            v-if="resource"
            variant="white"
            class="ml-auto btn-min-width"
            :resource="resource"
            :to-delete="toDelete"
            @click="onShowConfirmation"
        >
            {{ actionLabel }}
        </btn-resource>

        <b-btn
            v-else
            variant="white"
            class="ml-auto btn-min-width"
            @click="onShowConfirmation"
        >
            {{ actionLabel }}
        </b-btn>
    </b-card>
</template>

<script>
import ShowModalConfirm from '@/components/common/mixins/ShowModalConfirm';
import Collection from '@/models/vue-mc/Collection';
import Model from '@/models/vue-mc/Model';

export default {
    name: 'DangerousActionCard',
    mixins: [ShowModalConfirm],
    props: {
        title: {
            type: String,
            default: undefined,
        },
        message: {
            type: String,
            default: undefined,
        },
        actionLabel: {
            type: String,
            default: function() {
                return this.$t('common.actions.delete');
            },
        },
        confirmationMessage: {
            type: String,
            required: true,
        },
        resource: {
            type: [Model, Collection],
            required: false,
            default: undefined,
        },
        toDelete: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        async onShowConfirmation() {
            const confirmed = await this.showModalConfirm(this.confirmationMessage);
            if (confirmed) {
                this.$emit('confirmed');
            }
        },
    },
};
</script>
